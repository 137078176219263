import {FormProvider, useForm} from "react-hook-form";
import {css, Theme} from "@emotion/react";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";

import {Button} from "@pg-design/button-module";
import {borderRadius, calculateRemSize} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {CheckboxControlled, InputField} from "@pg-design/rhf-components-module";
import {Text} from "@pg-design/text-module";
import {IAppError} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {RodoFormInfo} from "../../../../../../../application/components/RodoFormInfo";
import {useGetVendorDetailApplicationQuery} from "../../../../../../../vendor/api/get_vendor_detail";
import {leadButtonCopy} from "../../../../../../constants/lead_button_copy";
import {useOpenedLeadModalRequiredParams} from "../../../../../../hooks/use_lead_modal_required_params";
import {ILeadModalPriceConversationChatLeadFormHandleSubmit} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormHandleSubmit";
import {ILeadModalPriceConversationChatLeadFormValues} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormValues";

interface IProps {
    showForm: boolean;
    initialValues: ILeadModalPriceConversationChatLeadFormValues;
    disabled: boolean;
    handleSubmit: ILeadModalPriceConversationChatLeadFormHandleSubmit;
    handleFormInteraction: () => void;
    handleChangeData: () => void;
}

export function LeadModalPriceConversationChatLeadForm(props: IProps) {
    const {initialValues, disabled, handleSubmit, handleFormInteraction, showForm, handleChangeData} = props;

    const {vendorId} = useOpenedLeadModalRequiredParams("property");
    const {data: vendorDetail} = useGetVendorDetailApplicationQuery(vendorId);

    const form = useForm<ILeadModalPriceConversationChatLeadFormValues>({
        disabled,
        defaultValues: initialValues,
        resolver: yupResolver(formSchema),
        mode: "onBlur"
    });

    const onSubmit = async (formValues: ILeadModalPriceConversationChatLeadFormValues) => {
        handleSubmit(formValues, (error) => {
            if (isValidationError(error)) {
                for (const fieldName of Object.keys(error.appError.fieldErrors)) {
                    const errorMessage = error.appError.fieldErrors[fieldName];
                    form.setError(fieldName as keyof ILeadModalPriceConversationChatLeadFormValues, {
                        message: errorMessage.join(", ")
                    });
                }
            }
        });
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} css={fields}>
                {showForm ? (
                    <>
                        <InputField name="name" placeholder="Imię i nazwisko" onClick={handleFormInteraction} />
                        <InputField name="email" placeholder="E-mail" onClick={handleFormInteraction} />
                        <InputField name="phone" placeholder="Telefon" type="number" pattern="[0-9]*" onClick={handleFormInteraction} />
                    </>
                ) : (
                    <div css={filledData}>
                        <Text variant="mini_header">Twoje dane</Text>
                        <Text variant="body_copy_2">
                            Imię i nazwisko: <strong>{initialValues.name}</strong>
                            <br />
                            e-mail: <strong>{initialValues.email}</strong>
                            <br />
                            Telefon: <strong>{initialValues.phone}</strong>
                        </Text>
                        <Link css={linkDecoration} onClick={handleChangeData}>
                            Zmień dane
                        </Link>
                    </div>
                )}
                <div css={optins}>
                    {(showForm || (!showForm && !initialValues.new_property_notification_consent)) && (
                        <CheckboxControlled
                            name="new_property_notification_consent"
                            id="new_property_notification_consent"
                            labelContent={
                                <Text variant="info_txt_2">
                                    Chcę otrzymywać mailem lub SMSem linki do nowych ofert nieruchomości. Zgodę mogę zawsze odwołać.
                                </Text>
                            }
                            checkedMarkColor="#02D054"
                        />
                    )}
                    {(showForm || (!showForm && !initialValues.financing_services)) && (
                        <CheckboxControlled
                            name="financing_services"
                            id="financing_services"
                            labelContent={<Text variant="info_txt_2">Poproszę o przedstawienie oferty kredytu na to mieszkanie.</Text>}
                            checkedMarkColor="#02D054"
                        />
                    )}
                </div>
                <Button variant="filled_primary" type="submit" css={button} onClick={handleFormInteraction} disabled={disabled} isLoading={disabled}>
                    {leadButtonCopy}
                </Button>
                <RodoFormInfo vendor={vendorDetail} css={rodo} />
                <Text variant="info_txt_2" as="span" css={terms}>
                    Akceptuję{" "}
                    <Link href={termsOfService} size="inherit" target="_blank" css={linkDecoration}>
                        regulamin serwisu.
                    </Link>
                </Text>
            </form>
        </FormProvider>
    );
}

// Utils
const formSchema = object().shape({
    name: string().required(),
    email: string().required().email(),
    phone: string().required()
});

function isValidationError(error: unknown): error is {appError: IAppError} {
    return typeof error === "object" && error !== null && "appError" in error && (error.appError as IAppError).status === 400;
}

const termsOfService = rpAppLink.termsOfService.base();

// Styles
const fields = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
`;

const optins = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    padding: ${calculateRemSize(1)} 0;
`;

const rodo = css`
    padding: 0;
    margin: 0 0 ${calculateRemSize(1)} 0;
`;

const terms = css`
    text-align: center;
`;

const linkDecoration = css`
    text-decoration: underline;
`;

const button = css`
    height: ${calculateRemSize(5)};
`;

const filledData = (theme: Theme) => css`
    background: ${theme.colors.background[100]};
    padding: ${calculateRemSize(2)};
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    ${borderRadius(2)}
`;
import {FormProvider, useForm} from "react-hook-form";
import {css, Theme} from "@emotion/react";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";

import {Button} from "@pg-design/button-module";
import {borderRadius, calculateRemSize} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {CheckboxControlled, InputField} from "@pg-design/rhf-components-module";
import {Text} from "@pg-design/text-module";
import {IAppError} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {RodoFormInfo} from "../../../../../../../application/components/RodoFormInfo";
import {useGetVendorDetailApplicationQuery} from "../../../../../../../vendor/api/get_vendor_detail";
import {leadButtonCopy} from "../../../../../../constants/lead_button_copy";
import {useOpenedLeadModalRequiredParams} from "../../../../../../hooks/use_lead_modal_required_params";
import {ILeadModalPriceConversationChatLeadFormHandleSubmit} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormHandleSubmit";
import {ILeadModalPriceConversationChatLeadFormValues} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormValues";

interface IProps {
    showForm: boolean;
    initialValues: ILeadModalPriceConversationChatLeadFormValues;
    disabled: boolean;
    handleSubmit: ILeadModalPriceConversationChatLeadFormHandleSubmit;
    handleFormInteraction: () => void;
    handleChangeData: () => void;
}

export function LeadModalPriceConversationChatLeadForm(props: IProps) {
    const {initialValues, disabled, handleSubmit, handleFormInteraction, showForm, handleChangeData} = props;

    const {vendorId} = useOpenedLeadModalRequiredParams("property");
    const {data: vendorDetail} = useGetVendorDetailApplicationQuery(vendorId);

    const form = useForm<ILeadModalPriceConversationChatLeadFormValues>({
        disabled,
        defaultValues: initialValues,
        resolver: yupResolver(formSchema),
        mode: "onBlur"
    });

    const onSubmit = async (formValues: ILeadModalPriceConversationChatLeadFormValues) => {
        handleSubmit(formValues, (error) => {
            if (isValidationError(error)) {
                for (const fieldName of Object.keys(error.appError.fieldErrors)) {
                    const errorMessage = error.appError.fieldErrors[fieldName];
                    form.setError(fieldName as keyof ILeadModalPriceConversationChatLeadFormValues, {
                        message: errorMessage.join(", ")
                    });
                }
            }
        });
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} css={fields}>
                {showForm ? (
                    <>
                        <InputField name="name" placeholder="Imię i nazwisko" onClick={handleFormInteraction} />
                        <InputField name="email" placeholder="E-mail" onClick={handleFormInteraction} />
                        <InputField name="phone" placeholder="Telefon" type="number" pattern="[0-9]*" onClick={handleFormInteraction} />
                    </>
                ) : (
                    <div css={filledData}>
                        <Text variant="mini_header">Twoje dane</Text>
                        <Text variant="body_copy_2">
                            Imię i nazwisko: <strong>{initialValues.name}</strong>
                            <br />
                            e-mail: <strong>{initialValues.email}</strong>
                            <br />
                            Telefon: <strong>{initialValues.phone}</strong>
                        </Text>
                        <Link css={linkDecoration} onClick={handleChangeData}>
                            Zmień dane
                        </Link>
                    </div>
                )}
                <div css={optins}>
                    {(showForm || (!showForm && !initialValues.new_property_notification_consent)) && (
                        <CheckboxControlled
                            name="new_property_notification_consent"
                            id="new_property_notification_consent"
                            labelContent={
                                <Text variant="info_txt_2">
                                    Chcę otrzymywać mailem lub SMSem linki do nowych ofert nieruchomości. Zgodę mogę zawsze odwołać.
                                </Text>
                            }
                            checkedMarkColor="#02D054"
                        />
                    )}
                    {(showForm || (!showForm && !initialValues.financing_services)) && (
                        <CheckboxControlled
                            name="financing_services"
                            id="financing_services"
                            labelContent={<Text variant="info_txt_2">Poproszę o przedstawienie oferty kredytu na to mieszkanie.</Text>}
                            checkedMarkColor="#02D054"
                        />
                    )}
                </div>
                <Button variant="filled_primary" type="submit" css={button} onClick={handleFormInteraction} disabled={disabled} isLoading={disabled}>
                    {leadButtonCopy}
                </Button>
                <RodoFormInfo vendor={vendorDetail} css={rodo} />
                <Text variant="info_txt_2" as="span" css={terms}>
                    Akceptuję{" "}
                    <Link href={termsOfService} size="inherit" target="_blank" css={linkDecoration}>
                        regulamin serwisu.
                    </Link>
                </Text>
            </form>
        </FormProvider>
    );
}

// Utils
const formSchema = object().shape({
    name: string().required(),
    email: string().required().email(),
    phone: string().required()
});

function isValidationError(error: unknown): error is {appError: IAppError} {
    return typeof error === "object" && error !== null && "appError" in error && (error.appError as IAppError).status === 400;
}

const termsOfService = rpAppLink.termsOfService.base();

// Styles
const fields = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
`;

const optins = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    padding: ${calculateRemSize(1)} 0;
`;

const rodo = css`
    padding: 0;
    margin: 0 0 ${calculateRemSize(1)} 0;
`;

const terms = css`
    text-align: center;
`;

const linkDecoration = css`
    text-decoration: underline;
`;

const button = css`
    height: ${calculateRemSize(5)};
`;

const filledData = (theme: Theme) => css`
    background: ${theme.colors.background[100]};
    padding: ${calculateRemSize(2)};
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    ${borderRadius(2)}
`;
